/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const DiversityInclusionTracker = () => {
  // Metric categories and indicators
  const metricCategories = {
    workforce_diversity: {
      title: "Workforce Diversity",
      description: "Track demographic representation across organization levels",
      metrics: {
        gender_diversity: {
          name: "Gender Diversity",
          benchmark: 0.50, // 50% target
          weight: 1.0
        },
        ethnic_diversity: {
          name: "Ethnic Diversity",
          benchmark: 0.35, // 35% target
          weight: 1.0
        },
        age_diversity: {
          name: "Age Diversity",
          benchmark: 0.30, // 30% target for underrepresented age groups
          weight: 0.8
        },
        disability_inclusion: {
          name: "Disability Inclusion",
          benchmark: 0.07, // 7% target
          weight: 0.8
        },
        veteran_status: {
          name: "Veteran Status",
          benchmark: 0.05, // 5% target
          weight: 0.7
        }
      }
    },
    leadership_representation: {
      title: "Leadership Representation",
      description: "Measure diversity in management and executive positions",
      metrics: {
        women_in_leadership: {
          name: "Women in Leadership",
          benchmark: 0.40, // 40% target
          weight: 1.0
        },
        minority_in_leadership: {
          name: "Minorities in Leadership",
          benchmark: 0.30, // 30% target
          weight: 1.0
        },
        promotion_equity: {
          name: "Promotion Equity",
          benchmark: 1.0, // 1.0 means equal promotion rates
          weight: 0.9
        }
      }
    },
    recruitment_hiring: {
      title: "Recruitment & Hiring",
      description: "Evaluate diversity in talent acquisition processes",
      metrics: {
        diverse_candidate_pool: {
          name: "Diverse Candidate Pool",
          benchmark: 0.50, // 50% target
          weight: 1.0
        },
        diverse_interview_panels: {
          name: "Diverse Interview Panels",
          benchmark: 1.0, // 100% target
          weight: 0.8
        },
        hiring_conversion_equity: {
          name: "Hiring Conversion Equity",
          benchmark: 1.0, // 1.0 means equal conversion rates
          weight: 0.9
        }
      }
    },
    inclusion_engagement: {
      title: "Inclusion & Engagement",
      description: "Assess workplace culture and employee experience",
      metrics: {
        belonging_score: {
          name: "Belonging Score",
          benchmark: 0.85, // 85% target
          weight: 1.0
        },
        inclusion_survey_score: {
          name: "Inclusion Survey Score",
          benchmark: 0.80, // 80% target
          weight: 1.0
        },
        erg_participation: {
          name: "ERG Participation",
          benchmark: 0.40, // 40% target
          weight: 0.8
        }
      }
    },
    development_advancement: {
      title: "Development & Advancement",
      description: "Track career growth and opportunities",
      metrics: {
        mentorship_participation: {
          name: "Mentorship Participation",
          benchmark: 0.60, // 60% target
          weight: 0.9
        },
        training_completion: {
          name: "D&I Training Completion",
          benchmark: 0.95, // 95% target
          weight: 0.8
        },
        advancement_equity: {
          name: "Advancement Equity",
          benchmark: 1.0, // 1.0 means equal advancement rates
          weight: 1.0
        }
      }
    },
    supplier_diversity: {
      title: "Supplier Diversity",
      description: "Monitor diversity in vendor and supplier relationships",
      metrics: {
        diverse_suppliers: {
          name: "Diverse Suppliers",
          benchmark: 0.25, // 25% target
          weight: 0.9
        },
        supplier_spending: {
          name: "Diverse Supplier Spending",
          benchmark: 0.20, // 20% target
          weight: 0.9
        },
        supplier_mentorship: {
          name: "Supplier Mentorship Program",
          benchmark: 1.0, // 100% program implementation
          weight: 0.7
        }
      }
    }
  }

  // State management
  const [trackingData, setTrackingData] = useState({
    organization_size: "",
    industry: "",
    region: "",
    metrics: {},
    submitted: false
  })

  // Industry options
  const industries = {
    technology: "Technology",
    healthcare: "Healthcare",
    finance: "Finance",
    retail: "Retail",
    manufacturing: "Manufacturing",
    consulting: "Consulting",
    education: "Education",
    government: "Government"
  }

  // Region options
  const regions = {
    northeast: "Northeast",
    southeast: "Southeast",
    midwest: "Midwest",
    southwest: "Southwest",
    west: "West",
    international: "International"
  }

  // Organization size options
  const organizationSizes = {
    "1-100": "1-100 employees",
    "101-500": "101-500 employees",
    "501-1000": "501-1,000 employees",
    "1001-5000": "1,001-5,000 employees",
    "5001-10000": "5,001-10,000 employees",
    "10000+": "10,000+ employees"
  }

  // Handle input changes
  const handleInputChange = (field, value) => {
    setTrackingData(prev => ({
      ...prev,
      [field]: value
    }))
  }

  // Handle metric value changes
  const handleMetricChange = (category, metric, value) => {
    setTrackingData(prev => ({
      ...prev,
      metrics: {
        ...prev.metrics,
        [category]: {
          ...prev.metrics[category],
          [metric]: Number(value) || 0
        }
      }
    }))
  }

  // Calculate category score
  const calculateCategoryScore = (category) => {
    const categoryMetrics = metricCategories[category].metrics
    const categoryData = trackingData.metrics[category]
    if (!categoryData) return 0

    let totalScore = 0
    let totalWeight = 0

    Object.entries(categoryMetrics).forEach(([metric, details]) => {
      const value = categoryData[metric] || 0
      const score = Math.min(value / details.benchmark, 1) * 100
      totalScore += score * details.weight
      totalWeight += details.weight
    })

    return totalWeight > 0 ? totalScore / totalWeight : 0
  }

  // Calculate overall score
  const calculateOverallScore = () => {
    const categoryScores = Object.keys(metricCategories).map(calculateCategoryScore)
    return categoryScores.reduce((a, b) => a + b, 0) / categoryScores.length
  }

  // Generate recommendations
  const generateRecommendations = () => {
    const recommendations = []
    
    Object.entries(metricCategories).forEach(([category, data]) => {
      const categoryScore = calculateCategoryScore(category)
      const categoryMetrics = trackingData.metrics[category] || {}
      
      Object.entries(data.metrics).forEach(([metric, details]) => {
        const value = categoryMetrics[metric] || 0
        const achievement = value / details.benchmark

        if (achievement < 0.6) {
          recommendations.push({
            category: data.title,
            metric: details.name,
            priority: "high",
            score: achievement * 100,
            actions: getActionItems(category, metric, "critical")
          })
        } else if (achievement < 0.8) {
          recommendations.push({
            category: data.title,
            metric: details.name,
            priority: "medium",
            score: achievement * 100,
            actions: getActionItems(category, metric, "improvement")
          })
        }
      })
    })

    return recommendations.sort((a, b) => a.score - b.score)
  }

  // Get action items based on metric and level
  const getActionItems = (category, metric, level) => {
    const actionItems = {
      workforce_diversity: {
        gender_diversity: {
          critical: [
            "Review and revise job descriptions to eliminate gender bias",
            "Implement blind resume screening process",
            "Partner with organizations focused on women in industry"
          ],
          improvement: [
            "Enhance parental leave and flexible work policies",
            "Create mentorship programs for women",
            "Conduct gender pay equity analysis"
          ]
        },
        ethnic_diversity: {
          critical: [
            "Expand recruitment channels to reach diverse communities",
            "Establish partnerships with minority professional organizations",
            "Implement diverse slate requirements"
          ],
          improvement: [
            "Create cultural awareness training programs",
            "Develop minority mentorship initiatives",
            "Review promotion processes for bias"
          ]
        }
      },
      leadership_representation: {
        women_in_leadership: {
          critical: [
            "Create leadership development program for women",
            "Set specific targets for women in leadership roles",
            "Review promotion criteria for gender bias"
          ],
          improvement: [
            "Enhance sponsorship opportunities",
            "Provide executive coaching support",
            "Create women's leadership network"
          ]
        },
        minority_in_leadership: {
          critical: [
            "Implement diverse succession planning",
            "Create minority leadership development program",
            "Set clear diversity goals for leadership positions"
          ],
          improvement: [
            "Enhance mentorship opportunities",
            "Provide leadership visibility opportunities",
            "Create networking opportunities"
          ]
        }
      }
    }

    return actionItems[category]?.[metric]?.[level] || [
      "Review current policies and procedures",
      "Set specific improvement targets",
      "Implement regular monitoring and reporting"
    ]
  }

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    setTrackingData(prev => ({
      ...prev,
      submitted: true
    }))
  }

  return (
    <Layout className="diversity-inclusion-tracker-page">
      <SEO 
        title="Diversity & Inclusion Progress Tracker | Measure D&I Initiatives" 
        description="Track and analyze diversity and inclusion metrics, benchmark against industry standards, and get actionable recommendations."
      />
      <div className="wrapper" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <h1 sx={{ fontSize: ['1.75rem', '2rem', '2.25rem'], mb: 4 }}>
          Diversity & Inclusion Progress Tracker
        </h1>

        {/* Description Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.3rem', mb: 3, color: 'primary' }}>About This Tool</h2>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
            Track and measure your organization's diversity and inclusion progress across key metrics. 
            This tool helps you:
          </p>
          <ul sx={{ 
            listStyle: 'disc',
            pl: 4,
            mb: 3,
            '& li': { 
              mb: 2,
              fontSize: '1rem',
              lineHeight: '1.5'
            }
          }}>
            <li>Measure progress across multiple D&I dimensions</li>
            <li>Benchmark against industry standards</li>
            <li>Identify areas for improvement</li>
            <li>Get actionable recommendations</li>
          </ul>
        </div>

        {!trackingData.submitted ? (
          <form onSubmit={handleSubmit}>
            {/* Organization Information */}
            <div sx={{ 
              bg: 'white', 
              p: 4, 
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              mb: 4
            }}>
              <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Organization Information</h2>
              <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'], gap: 4 }}>
                <div>
                  <label htmlFor="organization_size" sx={{ display: 'block', mb: 2 }}>
                    Organization Size
                  </label>
                  <select
                    id="organization_size"
                    value={trackingData.organization_size}
                    onChange={(e) => handleInputChange('organization_size', e.target.value)}
                    required
                    sx={{
                      width: '100%',
                      p: 2,
                      border: '1px solid',
                      borderColor: 'gray.3',
                      borderRadius: '4px'
                    }}
                  >
                    <option value="">Select size</option>
                    {Object.entries(organizationSizes).map(([key, label]) => (
                      <option key={key} value={key}>{label}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="industry" sx={{ display: 'block', mb: 2 }}>
                    Industry
                  </label>
                  <select
                    id="industry"
                    value={trackingData.industry}
                    onChange={(e) => handleInputChange('industry', e.target.value)}
                    required
                    sx={{
                      width: '100%',
                      p: 2,
                      border: '1px solid',
                      borderColor: 'gray.3',
                      borderRadius: '4px'
                    }}
                  >
                    <option value="">Select industry</option>
                    {Object.entries(industries).map(([key, label]) => (
                      <option key={key} value={key}>{label}</option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor="region" sx={{ display: 'block', mb: 2 }}>
                    Primary Region
                  </label>
                  <select
                    id="region"
                    value={trackingData.region}
                    onChange={(e) => handleInputChange('region', e.target.value)}
                    required
                    sx={{
                      width: '100%',
                      p: 2,
                      border: '1px solid',
                      borderColor: 'gray.3',
                      borderRadius: '4px'
                    }}
                  >
                    <option value="">Select region</option>
                    {Object.entries(regions).map(([key, label]) => (
                      <option key={key} value={key}>{label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Metric Categories */}
            {Object.entries(metricCategories).map(([categoryKey, category]) => (
              <div key={categoryKey} sx={{ 
                bg: 'white', 
                p: 4, 
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                mb: 4
              }}>
                <h2 sx={{ fontSize: '1.5rem', mb: 2, color: 'primary' }}>{category.title}</h2>
                <p sx={{ fontSize: '1rem', mb: 4, color: 'gray.7' }}>{category.description}</p>
                
                <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr'], gap: 4 }}>
                  {Object.entries(category.metrics).map(([metricKey, metric]) => (
                    <div key={metricKey}>
                      <label htmlFor={`${categoryKey}-${metricKey}`} sx={{ display: 'block', mb: 2 }}>
                        {metric.name}
                        <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                          Target: {(metric.benchmark * 100).toFixed(0)}%
                        </span>
                      </label>
                      <input
                        type="number"
                        id={`${categoryKey}-${metricKey}`}
                        value={trackingData.metrics[categoryKey]?.[metricKey] || ""}
                        onChange={(e) => handleMetricChange(categoryKey, metricKey, e.target.value)}
                        min="0"
                        max="100"
                        step="0.1"
                        required
                        sx={{
                          width: '100%',
                          p: 2,
                          border: '1px solid',
                          borderColor: 'gray.3',
                          borderRadius: '4px'
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}

            {/* Submit Button */}
            <div sx={{ 
              display: 'flex', 
              justifyContent: 'flex-end',
              mb: 4
            }}>
              <button
                type="submit"
                sx={{
                  px: 4,
                  py: 2,
                  bg: 'primary',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  '&:hover': {
                    bg: 'primaryDark'
                  }
                }}
              >
                Generate Report
              </button>
            </div>
          </form>
        ) : (
          <>
            {/* Results Section */}
            <div sx={{ 
              bg: 'white', 
              p: 4, 
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              mb: 4
            }}>
              <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>D&I Progress Report</h2>
              
              {/* Overall Score */}
              <div sx={{ 
                textAlign: 'center',
                p: 4,
                bg: 'gray.1',
                borderRadius: '8px',
                mb: 4
              }}>
                <h3 sx={{ fontSize: '1.2rem', mb: 2 }}>Overall D&I Score</h3>
                <p sx={{ 
                  fontSize: '2.5rem', 
                  fontWeight: 'bold',
                  color: calculateOverallScore() >= 80 ? 'green' :
                         calculateOverallScore() >= 60 ? 'orange' : 'red'
                }}>
                  {calculateOverallScore().toFixed(1)}%
                </p>
              </div>

              {/* Category Scores */}
              <div sx={{ 
                display: 'grid', 
                gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
                gap: 4,
                mb: 4
              }}>
                {Object.entries(metricCategories).map(([category, data]) => (
                  <div key={category} sx={{
                    p: 3,
                    border: '1px solid',
                    borderColor: 'gray.2',
                    borderRadius: '4px'
                  }}>
                    <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>{data.title}</h4>
                    <p sx={{ 
                      fontSize: '1.5rem',
                      fontWeight: 'bold',
                      color: calculateCategoryScore(category) >= 80 ? 'green' :
                             calculateCategoryScore(category) >= 60 ? 'orange' : 'red'
                    }}>
                      {calculateCategoryScore(category).toFixed(1)}%
                    </p>
                  </div>
                ))}
              </div>
            </div>

            {/* Recommendations Section */}
            <div sx={{ 
              bg: 'white', 
              p: 4, 
              borderRadius: '8px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              mb: 4
            }}>
              <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Recommendations</h2>
              
              {/* High Priority */}
              {generateRecommendations().filter(r => r.priority === "high").length > 0 && (
                <div sx={{ mb: 4 }}>
                  <h3 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'red',
                    mb: 3
                  }}>
                    High Priority Actions
                  </h3>
                  {generateRecommendations()
                    .filter(r => r.priority === "high")
                    .map((recommendation, index) => (
                      <div key={`high-${index}`} sx={{ mb: 4 }}>
                        <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>
                          {recommendation.category} - {recommendation.metric} ({recommendation.score.toFixed(1)}%)
                        </h4>
                        <ul sx={{ 
                          listStyle: 'disc',
                          pl: 4,
                          '& li': { mb: 2 }
                        }}>
                          {recommendation.actions.map((action, actionIndex) => (
                            <li key={`high-${index}-${actionIndex}`}>{action}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
              )}

              {/* Medium Priority */}
              {generateRecommendations().filter(r => r.priority === "medium").length > 0 && (
                <div sx={{ mb: 4 }}>
                  <h3 sx={{ 
                    fontSize: '1.2rem', 
                    color: 'orange',
                    mb: 3
                  }}>
                    Areas for Improvement
                  </h3>
                  {generateRecommendations()
                    .filter(r => r.priority === "medium")
                    .map((recommendation, index) => (
                      <div key={`medium-${index}`} sx={{ mb: 4 }}>
                        <h4 sx={{ fontSize: '1.1rem', mb: 2 }}>
                          {recommendation.category} - {recommendation.metric} ({recommendation.score.toFixed(1)}%)
                        </h4>
                        <ul sx={{ 
                          listStyle: 'disc',
                          pl: 4,
                          '& li': { mb: 2 }
                        }}>
                          {recommendation.actions.map((action, actionIndex) => (
                            <li key={`medium-${index}-${actionIndex}`}>{action}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                </div>
              )}
            </div>

            {/* Action Buttons */}
            <div sx={{ 
              display: 'flex', 
              gap: 3,
              justifyContent: 'flex-end',
              mb: 4
            }}>
              <button
                type="button"
                onClick={() => window.print()}
                sx={{
                  px: 4,
                  py: 2,
                  bg: 'primary',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  '&:hover': {
                    bg: 'primaryDark'
                  }
                }}
              >
                Print Report
              </button>
              <button
                type="button"
                onClick={() => setTrackingData(prev => ({ ...prev, submitted: false }))}
                sx={{
                  px: 4,
                  py: 2,
                  bg: 'white',
                  color: 'primary',
                  border: '1px solid',
                  borderColor: 'primary',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '1rem',
                  '&:hover': {
                    bg: 'gray.1'
                  }
                }}
              >
                Start New Assessment
              </button>
            </div>
          </>
        )}
      </div>
    </Layout>
  )
}

export default DiversityInclusionTracker